import React from 'react';
import PropTypes from 'prop-types';
import Header from '@/components/layout/header';
import Footer from '@/components/layout/footer';

const DefaultLayout = ({ children, pageInfo }) => (
  <React.Fragment>
    <Header pageInfo={pageInfo} />
    <main fluid="true" className="px-0" style={{ minHeight: '70vh' }}>
      {children}
    </main>
    <Footer />
  </React.Fragment>
);
DefaultLayout.propTypes = {
  children: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  pageInfo: PropTypes.object,
};

DefaultLayout.defaultProps = {
  children: null,
  pageInfo: null,
};

export default DefaultLayout;

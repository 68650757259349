/* eslint-disable react/prop-types */
import { Link } from 'gatsby';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Initials from '../initials';
import Navigation from './navigation';
import MaintenanceBanner from '../maintenance/maintenanceBanner';

const Header = () => {
  return (
    <header>
      <Container className="p-0">
        <Navigation />
      </Container>
      {/* MAINTENANCE BANNER WHEN WE WANT TO DIRECT USERS TO OTHER SITE  */}
      {/* <MaintenanceBanner />       */}
    </header>
  );
};
export default Header;

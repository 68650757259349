import { Link } from 'gatsby';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
  <footer className="footer py-5">
    <Container>
      <Row className="flex-wrap">
        <Col lg={6}>
          <a href="/">
            <img
              src="/images/PPI_logo_reversed.svg"
              alt="Partnership for Pre-K Improvement"
            />
          </a>
        </Col>
        <Col className="text-left text-lg-right mt-3 mt-lg-0" lg={6}>
          <p className="mb-1">&copy; Cultivate Learning</p>
          <p className="d-flex justify-content-lg-end">
            <Link to="/privacy-policy/" className="pr-2">
              Privacy Policy
            </Link>
            {/* <span>|</span>
              <Link to="/terms/" className="px-2">
              Terms of Use
            </Link> */}
            <span>|</span>
            <a href="mailto:ppitoolkitinfo@uw.edu" className="pl-2">
              Contact Us
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;

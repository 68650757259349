import axios from 'axios';
import { navigate } from 'gatsby-link';
const baseUrl = process.env.GATSBY_API_URL;

// convenience method for checking if you are in a
// browser or building. local storage calls need this the most
export const isBrowser = () => typeof window !== 'undefined';

// convenience function to check if you are logged
// in or not.
export const isLoggedIn = () => {
  const user = getUser();
  return !!user.email;
};

// below are the headers to be sent
// if a token is in local storage, it will
// add it and then pass it on future calls.
const getHeaders = () => {
  const headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  };

  if (isBrowser() && isLoggedIn()) {
    const token = localStorage.getItem('idm_token');
    if (token) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem('idm_token');
    }
  }
  return headers;
};

// returns the current user
export const getUser = () =>
  isBrowser() && window.localStorage.getItem('idm_user')
    ? JSON.parse(window.localStorage.getItem('idm_user'))
    : {};

// General request function. Handles errors.
// Method: GET, POST, PUT, DELETe
// Endpoint: has the base url automatically added
// Data: object to be passed via POST, PUT
const makeRequest = async (
  method,
  endpoint,
  data = null,
  extraHeaders = {}
) => {
  let request = {
    method,
    url: baseUrl + endpoint,
    headers: { ...getHeaders(), ...extraHeaders },
  };
  if (data) {
    request.data = data;
  }
  let response = { error: false };

  try {
    response = await axios.request(request);

  } catch (error) {
    response = {
      error: true,
      message: error.response.data.message,
      errors: error.response.data.errors,
    };
  }
  return response;
};

// Login to the application
export const login = async (email, password) => {

  // make request
  let response = await makeRequest('post', '/login', { email, password });

  // if not an error, set the token and user
  if (!response.error && isBrowser()) {
    localStorage.setItem('idm_token', response.data.access_token);
    let my_user = JSON.stringify(await me(response.data.access_token));
    localStorage.setItem('idm_user', my_user);
  }

  return response;
};

// Create a new user
export const createNewUser = async (user) => {
  return await makeRequest('post', '/user', user);
};

// Update the user
export const updateUser = async (user) => {
  let response = await makeRequest('put', '/user/' + user.id, user);
  if (isBrowser() && !response.error) {
    localStorage.setItem('idm_user', JSON.stringify(await me()));
  }
  return response;
};

// Update user password
export const updatePassword = async (pw) => {
  let me = getUser();
  me.password = pw.newPassword;
  me.original_password = pw.oldPassword;
  return await makeRequest('put', '/user/' + me.id, me);
};

// Reset user password
export const resetPassword = async (data) => {
  return await makeRequest('post', '/user/reset-password', data);
};

// Forgot password
export const submitForgotPassword = async (data) => {
  return await makeRequest('post', '/user/password-reset', data);
};

// Get the current user. This needs to have an access
// key passed in for the login call because it doesn't
// seem to set in local storage before being used.
export const me = async (access_key = null) => {
  let localHeaders = {};
  if (access_key) {
    localHeaders['Authorization'] = 'Bearer ' + access_key;
  }
  const response = await makeRequest('get', '/me', null, localHeaders);
  
  if (!response.error) {
    return response.data;
  } else {
    return response;
  }
};

// Get all my assessments
export const getMyAssessments = async () => {
  return await makeRequest('get', '/assessment');
};

// Create new assessment
export const createNewAssessment = async () => {
  let me = getUser();
  return await makeRequest('post', '/assessment', {
    name: new Date().toLocaleDateString('en-US') + ' Assessment',
    owner_id: me.id,
    response: { init: true },
  });
};

export const getAssessment = async (id) => {
  return await makeRequest('get', '/assessment/' + id);
};

// api call to assessment controller in laravel to create current assessment csv report
export const createAssessmentCSV = async (id) => {
  return await makeRequest('post', '/assessment/' + id);
}
// api call to assessment controller in laravel to get assessment csv based off id
export const getAssessmentCSV = async (id) => {
  return await makeRequest('get', '/assessment/csv/' + id);
}



export const saveAssessment = async (selfAssessment) => {
  return await makeRequest(
    'put',
    '/assessment/' + selfAssessment.id,
    selfAssessment
  );
};

export const inviteToAssessment = async (id, data) => {
  return await makeRequest('post', '/assessment/invite/' + id, data);
};

export const deleteAssessment = async (id) => {
  return await makeRequest('delete', '/assessment/' + id);
};

export const logout = () => {
  window.localStorage.removeItem('idm_user');
  window.localStorage.removeItem('idm_token');
};

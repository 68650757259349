import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function Initials({ user }) {
  return (
    <React.Fragment>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>
            {user.first_name} {user.last_name}
          </Tooltip>
        }
      >
        <div className="initials mx-1">
          {user.first_name.substr(0, 1).toUpperCase() +
            user.last_name.substr(0, 1).toUpperCase()}
        </div>
      </OverlayTrigger>
    </React.Fragment>
  );
}

Initials.defaultProps = {
  user: { first_name: 'Demo', last_name: 'User' },
};

Initials.propTypes = {
  user: PropTypes.object,
};

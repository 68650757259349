import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'gatsby';
import tree from '@/data/navigation.json';
import Initials from '../initials';
import { getUser, isLoggedIn } from '@/services/api';

const Navigation = () => {
  const isActive = (props) => {
    if (props.isCurrent) return { 'aria-current': 'page' };
    else if (
      props.href == '/app/idm/' &&
      (props.location.pathname == '/login/' ||
        props.location.pathname == '/register/' ||
        props.location.pathname == '/forgot/' ||
        props.location.pathname == '/reset/' ||
        props.location.pathname.match(/\/app\/idm/g))
    ) {
      return { 'aria-current': 'page' };
    }
  };

  return (
    <React.Fragment>
      <Navbar expand="lg" className="py-0">
        <Navbar.Brand>
          <Link to="/">
            <img
              className="py-2 mt-1 ppiLogo"
              src="/images/PPI_Logo.svg"
              alt="Partnership for Pre-K Involvement"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="sr-only">Toggle navigation</span>
          <div className="d-flex">
            <div className="overflow-hidden">
              <div className="icon-bar top-bar" />
              <div className="icon-bar middle-bar" />
              <div className="icon-bar bottom-bar" />
            </div>
            <div className="menu-text">Menu</div>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {tree.map((page) => (
              <Link
                to={page.link}
                className="nav-link px-lg-3 text-right "
                role="button"
                key={page.name}
                getProps={isActive}
              >
                {page.name}
              </Link>
            ))}
          </Nav>
          <Navbar.Brand href="/app/profile/" className="ml-0 float-right">
            {isLoggedIn() ? <Initials user={getUser()} /> : ''}
          </Navbar.Brand>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
};

export default Navigation;

import React from 'react';

function MaintenanceBanner(){
    const divStyle = {
        width: '100%',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: 'bold',
        color: 'black',
        backgroundColor: '#f2fa14',
        padding: '1em 0em',
    };
    
    return(
        <div style={divStyle}>
            <p>The Partnership for Pre-K Improvement (PPI) Toolkit has moved here! {'>>> '} <a href="">wa-coachingcompanion.org</a></p>
        </div>
    );
}

  export default MaintenanceBanner;
  